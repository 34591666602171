var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative p-4" },
    [
      _vm.$store.state.loginLogoSrc
        ? _c("div", { staticClass: "text-center pb-4" }, [
            _c("img", {
              staticClass: "logo logo-header",
              attrs: { src: _vm.$store.state.loginLogoSrc, alt: "Login Logo" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "text-center my-4" }, [
        _c("h3", { staticClass: "mb-1 fs-4 fw-500" }, [
          _vm._v("Welcome to " + _vm._s(_vm.ecosystemName)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "_welcome-subtitle mb-0 fs-2 fw-300" }, [
          _vm._v(" Let's set up your account "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "align-left mb-3 diana-theme-text-secondary",
            staticStyle: { display: "flex", "align-items": "center" },
          },
          [
            _c("img", {
              staticClass: "logo logo-inner mr-2",
              attrs: { src: _vm.$resource["key-icon"], alt: "google" },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("Single sign on (SSO):")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            ref: "firstElement",
            staticClass:
              "btn-lg btn-secondary w-100 mb-3 accessibility-element",
            attrs: { tabindex: "1", type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.registerWithMicrosoft.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "logo logo-button",
              attrs: {
                src: _vm.$resource["microsoft-360-logo"],
                alt: "AzureAD",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "align-middle ml-2" }, [
              _vm._v("Continue with Microsoft"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn-lg btn-secondary w-100 accessibility-element",
            attrs: { tabindex: "2", type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.registerWithGoogle.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "logo logo-button",
              attrs: { src: _vm.$resource["google-logo"], alt: "google" },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "align-middle ml-2" }, [
              _vm._v("Continue with Google"),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("OrDivider"),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-1",
          attrs: { novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _vm.status.errorMessage
            ? _c("ErrorMessage", {
                staticClass: "mb-3",
                model: {
                  value: _vm.status.errorMessage,
                  callback: function ($$v) {
                    _vm.$set(_vm.status, "errorMessage", $$v)
                  },
                  expression: "status.errorMessage",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "fieldset",
            { attrs: { disabled: _vm.status.isLoading } },
            [
              _c("div", { staticClass: "position-relative" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.name,
                      expression: "formData.name",
                    },
                  ],
                  staticClass: "input-lg mb-3 accessibility-element",
                  attrs: {
                    tabindex: "3",
                    id: "frmName",
                    type: "text",
                    autocomplete: "name",
                    autocapitalize: "on",
                    autocorrect: "on",
                    spellcheck: "on",
                    placeholder: "Full name",
                  },
                  domProps: { value: _vm.formData.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "position-relative" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.email,
                      expression: "formData.email",
                    },
                  ],
                  staticClass: "input-lg mb-3 accessibility-element",
                  attrs: {
                    tabindex: "4",
                    id: "frmEmail",
                    type: "email",
                    autocomplete: "username",
                    autocapitalize: "none",
                    autocorrect: "off",
                    spellcheck: "off",
                    required: "",
                    placeholder: "Email address",
                  },
                  domProps: { value: _vm.formData.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex mb-3" }, [
                _c(
                  "div",
                  { staticClass: "flex-fill position-relative" },
                  [
                    this.passwordType === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.password,
                              expression: "formData.password",
                            },
                          ],
                          staticClass:
                            "input-lg mb-0 password-strength accessibility-element",
                          attrs: {
                            tabindex: "5",
                            id: "frmPassword",
                            autocomplete: "new-password",
                            required: "required",
                            placeholder: "Password",
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.formData.password)
                              ? _vm._i(_vm.formData.password, null) > -1
                              : _vm.formData.password,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.formData.password,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.formData,
                                      "password",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.formData,
                                      "password",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.formData, "password", $$c)
                              }
                            },
                          },
                        })
                      : this.passwordType === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.password,
                              expression: "formData.password",
                            },
                          ],
                          staticClass:
                            "input-lg mb-0 password-strength accessibility-element",
                          attrs: {
                            tabindex: "5",
                            id: "frmPassword",
                            autocomplete: "new-password",
                            required: "required",
                            placeholder: "Password",
                            type: "radio",
                          },
                          domProps: {
                            checked: _vm._q(_vm.formData.password, null),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.formData, "password", null)
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.password,
                              expression: "formData.password",
                            },
                          ],
                          staticClass:
                            "input-lg mb-0 password-strength accessibility-element",
                          attrs: {
                            tabindex: "5",
                            id: "frmPassword",
                            autocomplete: "new-password",
                            required: "required",
                            placeholder: "Password",
                            type: this.passwordType,
                          },
                          domProps: { value: _vm.formData.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formData,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                    _vm._v(" "),
                    _c("PasswordStrength", {
                      attrs: { value: _vm.formData.password },
                      on: { isValid: _vm.isPasswordValid },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn end-icon",
                        attrs: { type: "button" },
                        on: { click: _vm.toggle },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "18",
                              height: "14",
                              viewBox: "0 0 14 12",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M6.99992 0.677795C2.15143 0.677795 0.333252 5.52628 0.333252 5.52628C0.333252 5.52628 2.15143 10.3748 6.99992 10.3748C11.8484 10.3748 13.6666 5.52628 13.6666 5.52628C13.6666 5.52628 11.8484 0.677795 6.99992 0.677795ZM6.99992 1.88992C10.1975 1.88992 11.8148 4.47603 12.3385 5.52391C11.8142 6.56452 10.1848 9.16264 6.99992 9.16264C3.80234 9.16264 2.18501 6.57653 1.66138 5.52865C2.18623 4.48804 3.81507 1.88992 6.99992 1.88992ZM6.99992 3.10204C5.66113 3.10204 4.57568 4.18749 4.57568 5.52628C4.57568 6.86507 5.66113 7.95052 6.99992 7.95052C8.33871 7.95052 9.42416 6.86507 9.42416 5.52628C9.42416 4.18749 8.33871 3.10204 6.99992 3.10204ZM6.99992 4.31416C7.66962 4.31416 8.21204 4.85658 8.21204 5.52628C8.21204 6.19598 7.66962 6.7384 6.99992 6.7384C6.33022 6.7384 5.7878 6.19598 5.7878 5.52628C5.7878 4.85658 6.33022 4.31416 6.99992 4.31416Z",
                                fill: _vm.fillColor,
                                "fill-opacity": "0.54",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              !!_vm.formErrorMessages.length
                ? _c("ErrorMessage", {
                    staticClass: "mb-3",
                    model: {
                      value: _vm.formErrorMessages,
                      callback: function ($$v) {
                        _vm.formErrorMessages = $$v
                      },
                      expression: "formErrorMessages",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary btn-lg w-100 mb-3 accessibility-element",
                  style: { backgroundColor: _vm.$store.state.buttonColor },
                  attrs: {
                    tabindex: this.isValid() ? 6 : -1,
                    type: "submit",
                    disabled: this.isValid() === false,
                  },
                },
                [
                  _vm.status.isLoading
                    ? _c("Spinner")
                    : _c("span", [_vm._v("Register")]),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "text-center mb-0" }, [
                _vm._v("\n        Already have an account?\n        "),
                _c(
                  "a",
                  {
                    staticClass: "diana-theme-text-primary accessibility-link",
                    attrs: { tabindex: "7", href: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.navigateToLogin.apply(null, arguments)
                      },
                      blur: function ($event) {
                        return _vm.$emit(
                          "last-element-blur",
                          _vm.$refs.firstElement
                        )
                      },
                    },
                  },
                  [_vm._v("Login\n        ")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }