<template>
  <div class="_rx_">
    <!-- buttons -->
    <div class="d-flex align-items-center">
      <!-- drawer button -->
      <div ref="DrawerButton" class="d-flex h-100 align-items-center position-relative">
        <DrawerButton
          v-if="userData"
          :class="$store.state.appDrawer.show ? '_active mr-3' : 'mr-3'"
          @click.native.prevent="$store.state.appDrawer.show = !$store.state.appDrawer.show"
        />

        <AppTrayCard
          v-model="$store.state.appDrawer.show"
          class="position-absolute mt-2"
          style="left: 0; top: 100%"
        />
      </div>

      <!-- user toggle button -->
      <ToggleButton
        v-if="userData"
        :class="$store.state.active ? '_active' : ''"
        @click.native.prevent="$store.state.active = !$store.state.active"
      />

      <!-- login button -->
      <div v-else :class="'_login-btn-container rise-x'">
        <a href="" @click.prevent="$store.commit('ovNavigation/navigate', 'login')">Login</a>
      </div>
    </div>

    <div class="_rx_" ref="Content">
      <!-- overlay view -->
      <OverlayView v-if="ovComponent">
        <component :is="ovComponent" @last-element-blur="onLastAccessibleElementBlur"/>
      </OverlayView>

      <!-- main content -->
      <div ref="MainWrapper" :style="getWrapperStyle()" :class="wrapperClass">
        <!-- screen extension content -->
        <div class="_extension-content">
          <component
            :is="activeComponent.extension"
            v-if="activeComponent.extension && extensionPayload"
            :payload="extensionPayload"
          ></component>
        </div>

        <!-- screen main content -->
        <div class="_main-content">
          <!-- user profile is loading -->
          <template v-if="userStatus.isLoading">
            <div class="p-4 text-center">
              <Spinner />
            </div>
          </template>

          <!-- loading user profile has error -->
          <template v-else-if="userStatus.errorMessage">
            <div class="p-4 text-center">
              <h4 class="m-0">Unable to check session.</h4>
              <p class="m-0">{{ userStatus.errorMessage }}</p>
              <button type="button" class="btn-sm mt-2" @click.prevent="loadUser">Try Again</button>
            </div>
          </template>

          <!-- user profile is loaded -->
          <template v-else>
            <component :is="activeComponent.component" v-if="activeComponent.component"></component>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import querystring from 'querystring';
import ToggleButton from '@/components/ToggleButton';
import DrawerButton from '@/components/DrawerButton';
import OverlayView from '@/components/OverlayView';
import AppTrayCard from '@/components/AppTrayCard';

export default {
  components: {
    ToggleButton,
    DrawerButton,
    OverlayView,
    AppTrayCard,
  },
  data: () => ({
    mouseEventDownTarget: null,
    theme: 'diana-light',
  }),
  computed: {
    ...mapState('userProfile', {
      userData: 'data',
      userStatus: 'status',
    }),
    ...mapState('navigation', {
      extensionPayload: 'extensionPayload',
    }),
    ...mapGetters('ovNavigation', {
      ovComponent: 'currentViewComponent',
    }),
    activeComponent() {
      const screens = this.$store.state.navigation.screens;
      const currentScreen = this.$store.state.navigation.currentScreen;
      return screens[currentScreen] || {};
    },
    wrapperClass() {
      return {
        '_main-wrapper': true,
        _active: this.$store.state.active,
        '_active-extension': this.extensionPayload,
        [`_${this.$store.state.position}`]: true,
      };
    },
  },
  watch: {
    '$store.state.active'() {
      this.$store.state.navigation.extensionPayload = null;
      if (this.$store.state.active && !((this.userData || {}).user || {}).emailValidated) {
        this.loadUser();
      }
    },
    '$store.state.appTrayMountSelector'(value) {
      if (value) {
        const dom = document.querySelector(value);
        if (dom) {
          dom.appendChild(this.$refs.DrawerButton);
        }
      }
    },
  },
  created() {
    window.addEventListener('mousedown', this.windowMousedownHandler);
    window.addEventListener('mouseup', this.windowMouseupHandler);
    window.addEventListener('scroll', this.windowScrollHandler);
  },
  destroyed() {
    window.removeEventListener('mousedown', this.windowMousedownHandler);
    window.removeEventListener('mouseup', this.windowMouseupHandler);
    window.removeEventListener('scroll', this.windowScrollHandler);
  },
  mounted() {
    this.$nextTick(this.loadUser);
    document.body.appendChild(this.$refs.Content);
    document.body.setAttribute('data-theme', this.theme);
  },
  methods: {
    onLastAccessibleElementBlur(el) {
      el.focus();
    },
    isWithinTheComponent(element) {
      const mainWrapper = this.$refs.MainWrapper;
      if (mainWrapper) {
        const isWrapper = mainWrapper === element;
        const isWrapperChild = mainWrapper.contains && mainWrapper.contains(element);
        return isWrapper || isWrapperChild;
      }
      return false;
    },
    windowMousedownHandler(e) {
      if (this.$store.state.active && !this.isWithinTheComponent(e.target))
        this.mouseEventDownTarget = e.target;
    },
    windowMouseupHandler(e) {
      const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      const isSmallScreen = windowWidth < 1000;
      if (this.mouseEventDownTarget === e.target)
        setTimeout(() => {
          if (this.$store.state.active && !this.isWithinTheComponent(e.target)) {
            if (isSmallScreen && this.extensionPayload)
              this.$store.state.navigation.extensionPayload = null;
            else this.$store.state.active = false;
          }
        });
      this.mouseEventDownTarget = null;
    },
    windowScrollHandler() {
      this.$forceUpdate();
    },
    getWrapperStyle() {
      if (this.$store.state.navBarSelector) {
        const navBar = document.querySelector(this.$store.state.navBarSelector);
        if (navBar) {
          const windowHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight || 0,
          );
          const navBarHeight = Math.max(navBar.clientHeight, navBar.innerHeight || 0);
          const navBarTopOffset = navBar.getBoundingClientRect().top;

          let topSpace = navBarTopOffset + navBarHeight;
          topSpace = topSpace >= 0 ? topSpace : 0;

          return {
            height: topSpace ? `${windowHeight - topSpace}px` : undefined,
          };
        }
      }
      return {};
    },
    loadUser() {
      const vm = this;
      const { navigateUnauthorizedTo } = this.$store.state;
      const search = (window.location.search || '').replace(/^\?/, '');
      const query = querystring.decode(search);

      // if(query['returnUrl'] !== undefined) {
      //   this.$store.state.loginRedirectUrl = query['returnUrl'];
      // }

      if (
        query['plugin-rx-action'] === 'resetPassword' ||
        query['plugin-rx-action'] === 'login' ||
        query['plugin-rx-action'] === 'register' ||
        query['plugin-rx-action'] === 'verifyEmail' ||
        query['plugin-rx-action'] === 'forgotPassword'
      ) {
        //if (query.email && query.code) {
        this.$store.commit('ovNavigation/navigate', query['plugin-rx-action']);
        return;
        //}
      }

      const returnUrl = query['ReturnUrl'] || '';
      const returnUrlQuery = querystring.decode(returnUrl);

      if (returnUrlQuery['plugin-rx-action']) {
        this.$store.commit('ovNavigation/navigate', returnUrlQuery['plugin-rx-action']);
        return;
      }

      this.$store.dispatch('userProfile/loadProfile', {
        vm: vm,
        callback(user) {
          if (!user && navigateUnauthorizedTo) {
            vm.$store.commit('ovNavigation/navigate', navigateUnauthorizedTo);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$_size: 500px;
$_dbl_size: $_size * 2;

._main-wrapper {
  font-family: 'Noto Sans KR', sans-serif;
  display: flex;
  flex-direction: row;
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: $_size;
  bottom: 0;
  right: -100%;
  background-color: $bg;
  overflow: hidden;
  transition: right 400ms ease-in-out, left 400ms ease-in-out, max-width 200ms ease-in-out;
  z-index: 9999;

  &._active {
    right: 0%;
  }

  &._active-extension {
    max-width: 100%;
  }

  &._left {
    flex-direction: row-reverse;
    right: auto;
    left: -100%;

    &._active {
      right: auto;
      left: 0%;
    }
  }

  ._main-content {
    width: $_size;
    height: 100%;
    overflow: auto;
    transition: width 200ms ease-in-out;
  }

  ._extension-content {
    height: 100%;
    flex: 1 0 0;
    overflow: auto;
  }

  &._active-extension ._main-content {
    width: 0;
    overflow: hidden;

    @media (min-width: $_dbl_size) {
      width: $_size;
      overflow: auto;
    }
  }
}
</style>

<style lang="scss">
._rx_ {
  @import './scss/index';
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: $font-size-sm;
  height: 100%;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    font-size: $font-size-base;
  }

  ._main-wrapper {
    color: #f4f5f8;
  }

  ._login-btn-container {
    display: flex;
    color: $secondary;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    border-left: 1px solid grey;

    a,
    a:hover {
      color: $primary;
    }
  }
}
</style>
