<template>
  <div class="_header d-flex align-items-center justify-content-between pt-2 pb-2 pl-3 pr-3">
    <img v-if="$store.state.loginLogoSrc" class="logo logo-header" :src="$store.state.loginLogoSrc" alt="Header Logo" />
  </div>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss" scoped>
._header{
  border-bottom: 1px solid var(--background-card);
}
</style>

